import React, { Component } from "react";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import SideNav from  "../components/SideNav";
import PropTypes from "prop-types";

class NavBar extends Component {
    componentDidMount() {
        let elem = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elem.classList.add("is-sticky");
            } else {
                elem.classList.remove("is-sticky");
            }
        });
        let scrollWithOffset = (el, offset) => {
            const elementPosition = el.offsetTop - offset;
            window.scroll({
                top: elementPosition,
                left: 0,
                behavior: "smooth"
            });
        };
        this.setState({ scrollWithOffset });
    }

    openNews(){
        window.open("/news");
    }

    closeNavbar() {
        if (window.matchMedia("screen and (max-width: 991px)").matches) {
            document.getElementById("collaspe-btn").click();
        }
    }

    render() {
        return (
            <>
                <Navbar
                    sticky="top"
                    id="navbar"
                    bg="light"
                    expand="lg"
                    className="navbar navbar-expand-lg navbar-light bg-light"
                    collapseOnSelect={true}
                >
                    <Container>
                        <Navbar.Brand >
                            <Link
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={800}
                            >
                                <img src={this.props.logoImage} alt="logo-img" />
                            </Link>
                        </Navbar.Brand>

                        <Navbar.Toggle
                            aria-controls="basic-navbar-nav"
                            id="collaspe-btn" 
                        />
                        
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="home"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        首頁
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        獎項緣起
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="awards"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        獎項名稱
                                    </Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="timeline"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                        徵選期程
                                    </Link>
                                </Nav.Item>

                                {/* <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="stage"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                    紫絲帶講壇
                                    </Link>
                                </Nav.Item> */}

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="register"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                    我要報名
                                    </Link>
                                </Nav.Item>

                                {/* <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to={"news"}
                                        target="_blank"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.openNews}
                                    >
                                    最新消息
                                    </Link>
                                </Nav.Item> */}

                                <Nav.Item>
                                    <Link
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={800}
                                        className="nav-link"
                                        onClick={this.closeNavbar}
                                    >
                                    聯絡我們
                                    </Link>
                                </Nav.Item>
                                    {/* SideNav: src/components/SideNav */}
                                {/* 
                                <Nav.Item>
                                    
                                    <SideNav />
                                </Nav.Item>
                                */}
                            </Nav> 
                        </Navbar.Collapse>
                    </Container>
                </Navbar> 
            </> 
        );
    }
}

NavBar.propTypes = {
    logoImage: PropTypes.string
}
NavBar.defaultProps ={
    logoImage: require("../../src/assets/img/pr-logo-50px.png")
}

export default NavBar;
