import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Awards extends Component {
    render() {
        // Home Slides loop start
        const awardData = this.props.data.map(
            (Award, index) => (
            <div key={`${Awards.sectionId}_${index}`} className="col-lg-3 col-md-3 col-sm-12">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="award-title">
                                <h2>{Award.title}</h2>
                            </div>
                            <div className="award-text">
                                <p>{Award.content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )
        );

        return (
            <>
               <section id="awards" className="awards-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle}</h2><h3>{this.props.sectionTitleSpan}</h3>
                            <p>{this.props.sectionContent}</p>
                        </div>
                        <div className="row">
                            {awardData}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}


Awards.PropsTypes = {
    sectionId: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    data: PropTypes.array
}

Awards.defaultProps = {
    sectionId: "awards",
    sectionTitle: "獎項名稱",
    sectionTitleSpan: "",
    sectionContent: "",
    data: [
        {
            title: "紫絲帶獎",
            content: "任職於六大類防治或保護工作中，熱心積極、認真負責、表現優良、研提創新作為或具其他正向服務經驗且足堪表率者。獲頒紫絲帶獎，包含「特別貢獻獎」及「新銳獎」各1名。"
        },
        {
            title: "特別貢獻獎",
            content: "針對長期從事性別暴力防治或保護服務工作，服務年資達10年(含)以上且在該專業領域具特殊貢獻事蹟、深具影響力或卓越成就的工作者，選出特別貢獻獎1名。"
        },
        {
            title: "新銳獎",
            content: "針對辦理性別暴力防治或保護服務工作年資3年（含）以下的新進人員，工作表現傑出、具服務熱忱或優良事蹟足堪楷模等，選出新銳獎1名。"
        },
        {
            title: "防暴大使獎",
            content: "從本屆紫絲帶獎得主，擇數名（含新銳獎得主）於頒獎典禮當天登上紫絲帶講壇，以10分鐘分享自身從事保護工作的經驗或事蹟，並由評審團及現場觀眾進行投票計分，遴選出1名授予「防暴大使獎」榮銜，獨得新台幣五萬元獎金；未來將以防暴大使的名義，參與衛生福利部之國內外之交流與防治宣導工作。"
        }
    ]
}

export default Awards;
