import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import BannerThree2 from "../components/banner/BannerThree2";
// import Subscribe from "../components/Subscribe";
// import Welcome from "../components/Welcome";
import About from "../components/About";
import RotateSlide from "../components/RotateSlide";
import Awards from "../components/Awards";
import TimelinePage from "../components/Timeline";
// import Services from "../components/Services";
// import Team from "../components/Team";
// import Stage from "../components/Stage";
// import Features from "../components/Features";
import FormDownload from "../components/FormDownload";
// import Register from "../components/Register";
import RegisterEnd from "../components/RegisterEnd";
// import Feadback from "../components/Feadback";
// import NewsOne from "../components/NewsOne";
// import Work from "../components/Work";
import ContactUs from "../components/ContactUs";
import ReachUs from "../components/ReachUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";

class HomeThree extends Component {
    render() {
        return(
            <>
                {/* NavBar: src/components/NavBar */}
                <NavBar />

                {/* BannerThree: src/components/banner/BannerThree2 */}
                <BannerThree2 />

                {/* Subscribe: src/components/Subscribe */}
                {/* <Subscribe /> */}

                {/* Welcome: src/components/Welcome */}
                {/* <Welcome /> */}

                {/* About: src/components/About */}
                <About />
                
                {/* RotateSlide: src/components/RotateSlide */}
                <RotateSlide />
                {/* Services: src/components/Services */}
                <Awards />
                <TimelinePage />

                {/* Portfolio: src/components/Stage */}
                {/* <Stage /> */}

                {/* Pricing: src/components/Pricing */}
                {/* <Register /> */}
                <RegisterEnd />
                <FormDownload />

                {/* News: src/components/News */}
                {/* <News /> */}

                {/* ContactUs: src/components/ContactUs */}
                <ContactUs />

                {/* ReachUs: src/components/ReachUs */}
                <ReachUs />

                {/* Footer: src/components/Footer */}
                <Footer />
                
                {/* ScrollUpBtn: src/components/ScrollUpBtn */}
                <ScrollUpBtn />
            </>
        );
    }
}
export default HomeThree;


