import React, { Component } from "react";
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import validator from "validator";

export const endpoint = process.env.REACT_APP_ENDPOINT || "";
export const accountApi = axios.create({
  baseURL: endpoint,
});

export class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      content: "",
      loading: false,
      success: true,
      message: "",
    };
  }

  handleForm = (e) => {
    document.getElementById("btnContactSubmit").disabled = true;
    var formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("phone", this.state.phone);
    formData.append("email", this.state.email);
    formData.append("content", this.state.content);
    this.setState({ loading: true, message: "" });
    var self = this;
    accountApi
      .post("/ContactForms/Post", formData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const data = response.data;
        if (data.Success) {
          document.getElementById("contactForm").reset();
        }
        //console.log(data);
        self.setState({
          loading: false,
          success: data.Success,
          message: data.Message,
        });
        document.getElementById("btnContactSubmit").disabled = false;
      })
      .catch(function(error) {
        self.setState({
          loading: false,
          success: false,
          message: "上傳失敗，請再試一次",
        });
        document.getElementById("btnContactSubmit").disabled = false;
        console.log(error);
      });
  };

  handleFields = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;
    //Start ContactUs Loop
    const contactusdata = this.props.contactussData.map((contactus, index) => (
      <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
        <div className="contact-box">
          <div className="icon">
            <i className={contactus.boxicon} />
          </div>
          <h4>{contactus.boxtitle}</h4>
          <p>
            <a href={contactus.boxitem1link}>{contactus.boxitem1}</a>
          </p>
          <p>
            <a href={contactus.boxitem2link}>{contactus.boxitem2}</a>
          </p>
        </div>
      </div>
    ));
    //End ContactUs Loop

    return (
      <>
        <section id="contact" className="contact-area ptb-100">
          <div className="container">
            <div className="section-title">
              <h2>
                {this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b>
              </h2>
              <p>{this.props.sectionContent}</p>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                <img
                  src={this.props.sectionImage}
                  className="contactus-img"
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <ValidationForm
                  id="contactForm"
                  onSubmit={(e, formData, inputs) => {
                    e.preventDefault();
                    this.handleForm(formData);
                  }}
                  onErrorSubmit={this.handleErrorSubmit}
                >
                  <div className="form-group">
                    <TextInput
                      name="name"
                      id="name"
                      required
                      successMessage=""
                      errorMessage="請輸入姓名"
                      maxLength={64}
                      className="form-control"
                      placeholder="姓名"
                      autoComplete="off"
                      onChange={this.handleFields}
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <TextInput
                      name="phone"
                      id="phone"
                      type="phone"
                      required
                      maxLength={16}
                      errorMessage={{
                        required: "請輸入行動電話號碼",
                        validator: "請輸入正確的行動電話格式",
                      }}
                      validator={
                        !validator.isMobilePhone(this.state.phone, "zh-TW")
                      }
                      successMessage=""
                      className="form-control"
                      placeholder="行動電話號碼"
                      autoComplete="off"
                      onChange={this.handleFields}
                    />
                    <div className="help-block with-errors">
                      {this.state.phone_error}
                    </div>
                  </div>
                  <div className="form-group">
                    <TextInput
                      name="email"
                      id="email"
                      type="email"
                      maxLength={255}
                      validator={validator.isEmail}
                      errorMessage={{
                        required: "請輸入電子郵件",
                        validator: "請輸入正確電子郵件格式",
                      }}
                      required
                      successMessage=""
                      className="form-control"
                      placeholder="電子郵件"
                      autoComplete="off"
                      onChange={this.handleFields}
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="form-group">
                    <TextInput
                      name="content"
                      id="description"
                      multiline
                      maxLength={65535}
                      placeholder="訊息留言"
                      className="form-control"
                      required
                      successMessage=""
                      errorMessage="請輸入您的留言"
                      rows="5"
                      autoComplete="off"
                      onChange={this.handleFields}
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                  <div className="text-right">
                    <button
                      id="btnContactSubmit"
                      type="submit"
                      className="btn btn-primary"
                    >
                      寄出
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                  <BeatLoader
                    color="#c264d3"
                    loading={this.state.loading}
                    css={override}
                    size={15}
                  />
                  {this.state.message !== "" ? (
                    <h3 className="contactMsg">{this.state.message}</h3>
                  ) : null}
                </ValidationForm>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

ContactUs.PropsTypes = {
  sectionImage: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  fblink: PropTypes.string,
  twitterlink: PropTypes.string,
  instagramlink: PropTypes.string,
  linkedinlink: PropTypes.string,
  contactussData: PropTypes.array,
};

ContactUs.defaultProps = {
  sectionImage: require("../../src/assets/img/sections/contactus/trophy.png"),
  sectionTitle: "",
  sectionTitleSpan: "聯絡我們",
  sectionContent: "",
  title: "",
  content: "",
  fblink: "https://www.facebook.com/praward/",
  twitterlink: "#",
  instagramlink: "#",
  linkedinlink: "#",
  contactussData: [
    {
      boxicon: "icofont-location-pin",
      boxtitle: "地址",
      boxitem1link: "https://www.mohw.gov.tw/",
      boxitem1: "衛生福利部",
      boxitem2link: "https://goo.gl/maps/f3Z2VmaEAbrHbHaKA",
      boxitem2: "台北市南港區忠孝東路六段488號",
    },
    {
      boxicon: "icofont-mail",
      boxtitle: "Email",
      boxitem1link: "#",
      boxitem1: "contact@praward.tw",
      boxitem2link: "",
      boxitem2: "",
    },
    {
      boxicon: "icofont-phone",
      boxtitle: "電話",
      boxitem1link: "#",
      boxitem1: "報名事宜請洽 02-8590-6697 呂先生",
      boxitem2link: "#",
      boxitem2: "其他事宜請洽 0936-134-495 翁先生",
    },
  ],
};
export default ContactUs;
