import React, { Component } from 'react';
// import {Timeline, TimelineEvent} from 'react-event-timeline'
import PropTypes from "prop-types";

export class TimelinePage extends Component {

  render() {
    // const events = this.props.timelineEvents.map((item, index) =>
    //     {
    //         const cicle = (index === 0) ? 18 : 9;
    //         return (
    //             <TimelineEvent title={item.title}
    //                 createdAt={item.period}
    //                 icon={<i className={`material-icons md-${cicle}`}></i>} key={`timeline_${index}`}>
    //                 {item.desc}
    //             </TimelineEvent>
    //         )
    //     }
    // );
    const timelineData = this.props.data.map(
        (Events, index) => (
        <div key={`${TimelinePage.sectionId}_${index}`} className="col-lg-2 col-md-2 col-sm-12">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="timeline-title">
                            <h2>{index + 1}</h2>
                            <h2>{Events.title}</h2>
                        </div>
                        <div className="timeline-text">
                            <p>{Events.periodfrom}{Events.periodto.length > 0 && (<span><br/>~</span>)}{Events.periodto}
                                <br/>
                                {Events.desc}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    );
    return (
        <>
            <section 
                id="timeline" 
                className="timeline-area ptb-100"
            >
                <div className="container">
                    <div className="section-title">
                        <h2>{this.props.sectionTitle} {this.props.sectionTitleSpan}</h2>
                        <p>{this.props.sectionContent}</p>
                    </div>
                    {/* <Timeline>
                        {events}
                    </Timeline> */}
                    <div className="row">
                        {timelineData}
                    </div>
                </div>
            </section>
        </>
    );
  }
}
TimelinePage.propTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    data: PropTypes.array
}
TimelinePage.defaultProps ={
    sectionTitle: "徵選期程",
    sectionTitleSpan: "",
    sectionContent: "",
    data:[
        {
            periodfrom: "112年3月",
            periodto: "",
            title: "報名",
            desc: "受理推薦 網路報名"
        },
        {
            periodfrom: "112年4月",
            periodto: "",
            title: "初選",
            desc: "公布入圍名單"
        },
        {
            periodfrom: "112年5月",
            periodto: "",
            title: "面訪",
            desc: "面訪委員親訪入圍者"
        },
        {
            periodfrom: "112年6月",
            periodto: "",
            title: "決選",
            desc: "公布得獎名單"
        },
        {
            periodfrom: "112年7-10月",
            periodto: "",
            title: "專訪",
            desc: "拍攝得主得獎事蹟影片"
        },
        {
            periodfrom: "112年11月25日",
            periodto: "",
            title: "頒獎",
            desc: "紫絲帶講壇暨頒獎典禮"
        },
    ]
}
export default TimelinePage;
