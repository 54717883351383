import React, { Component } from 'react';
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";

export class About extends Component {
  render() {
    return (
        <>
            <section 
                id="about" 
                className="about-area ptb-100 bg-f9f9f9"
            >
                <div className="container">
                    <div className="row">
                        <div className="about-image">
                            <img src={this.props.sectionImage} alt="about-img" />
                        </div>
                        
                        <div className="col-lg-6 col-md-12 col-sm-12 offset-lg-6">
                            <div className="row">
                                <OwlCarousel 
                                    className="about-slider owl-theme"
                                    items={1}
                                    nav={false}
                                    dots={true}
                                    touchDrag={false}
                                    mouseDrag={false}
                                    autoplay={false}
                                    smartSpeed={700}
                                    loop={true}
                                    navText={[
                                        "<i class='icon icon-Arrow'></i>",
                                        "<i class='icon icon-Arrow'></i>"
                                    ]}
                                >
                                    <div className="col-lg-12">
                                        <div className="about-title area-title">
                                            <h2>{this.props.skillToptitle}</h2>
                                        </div>
                                        <div className="about-text">
                                            <p>{this.props.skillContent1}</p>
                                            <p>{this.props.skillContent2}</p>
                                            <p>{this.props.skillContent3}</p>
                                            <p>{this.props.skillContent4}</p>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
  }
}
About.propTypes = {
    sectionImage: PropTypes.string,

    skillToptitle: PropTypes.string,
    skillToptitlespan: PropTypes.string,
    skillTitle: PropTypes.string,
    skillTitlespan: PropTypes.string,
    skillContent1: PropTypes.string,
    skillContent2: PropTypes.string,
    skillContent3: PropTypes.string,
}
About.defaultProps ={
    sectionImage: require("../../src/assets/img/sections/about/circle.png"),
   
    skillToptitle: "獎項緣起",
    skillToptitlespan: "",
    skillTitle: "",
    skillTitlespan: "",
    skillContent1: "紫絲帶，一條緊緊繫住「暴力零容忍」信念的單純符號．多年來，在台灣的城市與鄉間不斷飄揚。從政府到民間、中央到地方，無數的防治網絡工作者緊緊串聯，他們奉獻一生、念茲在茲、殫精竭慮，在反暴力的道路上不斷向前。",
    skillContent2: "民國92年，內政部開始表揚家庭暴力、性侵害及性騷擾防治工作的有功人士，藉以肯定防治網絡的傑出成員們。民國102年7月，攸關民眾人身安全之保護性業務，集中於衛生福利部統籌辦理，形成專業分工、資源整合的新局面，民國103年舉辦「紫絲帶獎」，以「台灣保護服務工作的最高榮譽」為定位，持續表彰家庭暴力、性侵害、性騷擾、兒少保護、老人保護、身心障礙保護六大領域的防治夥伴。得獎者在「紫絲帶講壇」分享服務歷程，以召喚更多有識之士的服務熱忱，提高社會大眾對人身安全的關注與重視。",
    skillContent3: "紫絲帶得獎者，照亮了保護服務工作的頒獎台，也點亮了人心的勇氣與溫暖，這股不向暴力妥協的堅定信念，將讓保護服務工作的精神持續接棒與傳承。",
    
}
export default About;
