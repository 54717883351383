import React, { Component } from 'react';

//Components List
import BannerLogin from "../components/banner/BannerLogin";

class Login extends Component {
    render() {
        return(
            <>
                {/* BannerThree: src/components/banner/BannerThree */}
                <BannerLogin />
            </>
        );
    }
}
export default Login;


