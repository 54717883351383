import React, { Component } from 'react';
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from 'prop-types';

export class ReachUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        //Start ReachUs Loop
        const ReachUsdata = this.props.ReachUssData.map((ReachUs, index ) =>(
            <div className="col-lg-6 col-md-6 col-sm-6" key={index}>
                <div className="reachUs-box">
                    <div class="row">
                        <div className="col-md-4 col-sm-12 col-xs-12 box-content vertical-center">
                                <img src={ReachUs.boxicon} />
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <div class="vertical-center box-content">
                                <h4>{ReachUs.boxtitle}
                                    <a href={ReachUs.boxitem1link}>{ReachUs.boxitem1}</a><br/>
                                    <a href={ReachUs.boxitem2link}>{ReachUs.boxitem2}</a>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
        //End ReachUs Loop

        return (
            <>
                <section id="reachUs" className="reachUs-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                            <p>{this.props.sectionContent}</p>
                        </div>
                        <div className="row justify-content-center">
                            {ReachUsdata}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

ReachUs.PropsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    fblink: PropTypes.string,
    twitterlink: PropTypes.string,
    instagramlink: PropTypes.string,
    linkedinlink: PropTypes.string,
    ReachUssData: PropTypes.array,
}

ReachUs.defaultProps = {
    sectionTitle: "",
    sectionTitleSpan: "",
    sectionContent: "",
    title: "",
    content: "",
    fblink: "https://www.facebook.com/praward/",
    twitterlink: "#",
    instagramlink: "#",
    linkedinlink: "#",
    ReachUssData:[
        {
            boxicon: require("../../src/assets/img/mohw-logo.png"),
            boximage: "",
            boxtitle: "",
            boxitem1link: "https://www.mohw.gov.tw/",
            boxitem1: "衛生福利部",
            boxitem2link: "https://goo.gl/maps/f3Z2VmaEAbrHbHaKA",
            boxitem2: "台北市南港區忠孝東路六段488號",
        },
        {
            boxicon: require("../../src/assets/img/praward-logo-white.png"),
            boximage: "",
            boxtitle: "",
            boxitem1link: "tel:0285906697",
            boxitem1: "報名事宜請洽 02-8590-6697 呂先生",
            boxitem2link: "tel:0936134495",
            boxitem2: "其他事宜請洽 0936-134-495 翁先生",

        }
    ]
}
export default ReachUs
