import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import { ValidationForm, TextInput, FileInput } from "react-bootstrap4-form-validation";
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import validator from 'validator';

export const endpoint = process.env.REACT_APP_ENDPOINT || '';
export const accountApi = axios.create({
  baseURL: endpoint,
});

export class RegisterEnd extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor(props) {
        super(props);
        this.state =  this.getInitialState();
    }
    getInitialState = () => ({
        name: "",
        phone:"",
        email: "",
        file1: null,
        file2: null,
        file3: null,
        file1_name: "",
        file2_name: "",
        file3_name: "",
        file1_error: "",
        file2_error: "",
        file3_error: "",
        loading: false,
        success: true,
        message: ""
    });
    
    resetState = () => {
        this.setState(this.getInitialState());
    };

    handleForm = (data) => {
        var formData = new FormData();
        document.getElementById("btnSubmit").disabled = true;
        formData.append("name", document.getElementById("name").value);
        formData.append("phone", document.getElementById("phone").value);
        formData.append("email", document.getElementById("email").value);
        formData.append("file1", data.file1);
        formData.append("file2", data.file2);
        formData.append("file3", data.file3);

        // Display the key/value pairs
        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }
        let config = {
            header : {
              'Content-Type' : 'multipart/form-data'
            }
          }
        this.setState({ loading: true, message: ""});
        var self = this;
        accountApi
            .post("/Forms/Post", formData,config)
            .then(response=>{
                const data = response.data;
                //console.log(data);
                if(data.Success)
                {
                    document.getElementById("registerForm").reset();
                    self.resetState();
                }
                self.setState({
                    loading: false,
                    success: data.Success,
                    message: data.Message
                });
                document.getElementById("btnSubmit").disabled = false;
            })
            .catch(function(error) {
                self.setState({
                    loading: false,
                    success: false,
                    message: "上傳失敗，請再試一次"
                });
                document.getElementById("btnSubmit").disabled = false;
            });
    };


    handleFields = e => {
        console.log(" [e.target.name] =" +  e.target.name);
        console.log(" [e.target.value] =" +  e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }
    handleFileFields = e => {
        let files = e.target.files;
        this.setState({ [e.target.name]: files[0] });
        this.setState({ [`${e.target.name}_name`]: files[0].name });
       // console.log(this.state.file1.name);
        //this.setState({files:[ ...this.state.files, ...files]}, () => { console.log("files"); console.log(this.state.files); });
    };

    handleErrorSubmit = (e, errorInputs) => {
        console.error(errorInputs)
    }   

    render() {
        const override = css`
            display: block;
            margin: 0 auto;
            border-color: white;
        `;
        const lists = this.props.sectionContent.map((list, index) => <li key={index}>{list}</li>);
        return (
            <>
                <section id="register" className="register-area">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle}</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="register-title"><h2>{this.props.sectionTitleSpan}</h2></div>
                                <div>
                                    <ol>
                                        {lists}
                                    </ol>
                                </div>
                            </div>
                                
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <ValidationForm
                                        id="registerForm"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            this.handleForm(this.state);
                                        }}
                                        onErrorSubmit={this.handleErrorSubmit}
                                    >
                                            <div className="form-group">
                                                <TextInput
                                                    name="name"
                                                    id="name"
                                                    required
                                                    disabled
                                                    maxLength={64}
                                                    successMessage=""
                                                    errorMessage="請輸入聯絡人的姓名"
                                                    className="form-control"
                                                    placeholder="聯絡人姓名"
                                                    autoComplete="new-password"
                                                    onChange={
                                                        this.handleFields
                                                    }
                                                />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                            <div className="form-group">
                                            <TextInput
                                                name="phone"
                                                id="phone"
                                                type="phone"    
                                                required
                                                disabled
                                                errorMessage={{required: "請輸入聯絡人的行動電話號碼", validator: "請輸入正確的行動電話格式"}}
                                                validator= {!validator.isMobilePhone(this.state.phone, 'zh-TW')}
                                                maxLength={16}
                                                successMessage=""
                                                className="form-control"
                                                placeholder="聯絡人行動電話號碼"
                                                autoComplete="new-password"
                                                onChange={
                                                    this.handleFields
                                                }
                                            />
                                                <div className="help-block with-errors">{this.state.phone_error}</div>
                                            </div>
                                            <div className="form-group">
                                            <TextInput
                                                name="email"
                                                id="email"
                                                type="email"
                                                validator={validator.isEmail} 
                                                maxLength={255}
                                                errorMessage={{required:"請輸入聯絡人電子郵件", validator:"請輸入正確電子郵件格式"}}
                                                required
                                                disabled
                                                successMessage=""
                                                className="form-control"
                                                placeholder="聯絡人電子郵件"
                                                autoComplete="new-password"
                                                onChange={
                                                    this.handleFields
                                                }
                                            />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="file1" className="btn btn-light">上傳報名表</label>
                                                <span className="filename">{this.state.file1_name}</span>
                                                <FileInput 
                                                    name="file1" 
                                                    id="file1" 
                                                    required
                                                    disabled
                                                    fileType={["pdf"]} 
                                                    maxFileSize="30 MB" 
                                                    errorMessage={
                                                        {   required:"請準備上傳文件",
                                                            maxFileSize:"文件已超出大小限制30MB",
                                                            fileType: "請使用PDF文件上傳" 
                                                        }}
                                                    className="form-control"
                                                    placeholder="報名表"
                                                    autoComplete="new-password"
                                                    onChange={
                                                        this.handleFileFields
                                                    }
                                                />
                                                <div className="help-block with-errors">{this.state.file1_error}</div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="file2" className="btn btn-light">上傳同意書</label>
                                                <span className="filename">{this.state.file2_name}</span>
                                                <FileInput 
                                                    name="file2" 
                                                    id="file2" 
                                                    required
                                                    disabled
                                                    fileType={["pdf"]} 
                                                    maxFileSize="30 MB" 
                                                    errorMessage={
                                                        {   required:"請準備上傳文件",
                                                            maxFileSize:"文件已超出大小限制30MB",
                                                            fileType: "請使用PDF文件上傳" 
                                                        }}
                                                    className="form-control"
                                                    placeholder="同意書"
                                                    autoComplete="new-password"
                                                    onChange={
                                                        this.handleFileFields
                                                    }
                                                />
                                                <div className="help-block with-errors">{this.state.file2_error}</div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="file3" className="btn btn-light">上傳佐證資料</label>
                                                <span className="filename">{this.state.file3_name}</span>
                                                <FileInput 
                                                    name="file3" 
                                                    id="file3" 
                                                    required={false}
                                                    disabled
                                                    fileType={["pdf"]} 
                                                    maxFileSize="30 MB" 
                                                    errorMessage={
                                                        {   required:"請準備上傳文件",
                                                            maxFileSize:"文件已超出大小限制30MB",
                                                            fileType: "請使用PDF文件上傳" 
                                                        }}
                                                    className="form-control"
                                                    placeholder="佐證資料"
                                                    autoComplete="new-password"
                                                    onChange={
                                                        this.handleFileFields
                                                    }
                                                />
                                                <div className="help-block with-errors">{this.state.file3_error}</div>
                                            </div>
                                            <div className="text-right">
                                                {/* <button id="btnSubmit" type="submit" className="btn btn-primary">寄出</button> */}
                                                <button id="btnSubmit" type="submit" className="btn btn-secondary" disabled>報名已截止</button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                </ValidationForm>
                                <BeatLoader color="#ffffff" loading={this.state.loading} css={override} size={15} />
                                {this.state.message !== "" ? (
                                    <h3 className="registerMsg" id="registerMsg">
                                        {this.state.message}
                                    </h3>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

RegisterEnd.PropsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.array,
}

RegisterEnd.defaultProps = {
    sectionTitle: "我要報名",
    sectionTitleSpan: "注意事項",
    sectionContent: [
        "「報名表」與「同意書」為必備文件，請務必上傳。",
        "每份文件請轉成PDF檔案格式，且不得超過30M。",
        "上傳完畢後，接到系統報名成功確認信，代表完成報名。",
        "如遲未接獲確認信，請利用本網頁『聯絡我們』留言。",
        // "僅接受推薦，恕不接受自行報名（請參閱徵選辦法）。"
    ]
}

export default RegisterEnd
