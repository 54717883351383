import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

//Package CSS & JS
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS & CSS Style
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";

//Component Import
import Login from "./pages/Login";
import HomeThree from "./pages/HomeThree";
import News from "./pages/News";

class App extends Component {
  render() {
    return (
        <BrowserRouter>
            <div className="App">
                <div>
                        <Switch >
                            <Route
                                path="/news"
                                component={News}
                            />
                            <Route
                                path="/"
                                component={HomeThree}
                            />
                            {/* <Route
                                path="/uat"
                                component={HomeThree}
                            />
                            <Route
                                path="/"
                                component={Login}
                            /> */}
                        </Switch>
                </div>
        </div>
      </BrowserRouter>
    );
  }
}
export default App;
