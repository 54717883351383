import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class NewsDetail extends Component {
    render() {
        return (
            <>
            <section className="news-area">
                <div id="news" className="main-banner item-bg-four">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="hero-content">
                                    <h1>
以下空白
                                    </h1>

                                    <h5>{this.props.content}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        );
    }
}

export default NewsDetail
