import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";

class rotateSlides extends Component {
    render() {
        // Home Slides loop start
        const rotateSlidesData = this.props.data.map(
            (rotateSlides, index) => (
                <div key={index}>
                    <div className={rotateSlides.bgImgClass}>
                        <div className="d-table">
                            <div className="d-table-cell">
                                <div className="container">
                                    <VisibilitySensor>
                                        {({ isVisible }) => (
                                            <div className="rotate-content">
                                                <h1
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {rotateSlides.toptitle}
                                                </h1>
                                            </div>
                                        )}
                                    </VisibilitySensor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );

        return (
            <>
                <section 
                id="rotate" 
                className="rotate-area ptb-100">
                    <OwlCarousel
                        className="home-slides owl-theme"
                        items={1}
                        nav={false}
                        dots={false}
                        loop={true}
                        touchDrag={false}
                        mouseDrag={false}
                        autoplay={true}
                        autoplayHoverPause={true}
                        animateOut={"slideOutDown"}
                        animateIn={"slideInDown"}
                        smartSpeed={700}
                        autoplayTimeout ={5000}
                        navText={[
                            "<i class='icofont-swoosh-right'></i>",
                            "<i class='icofont-swoosh-right'></i>"
                        ]}
                    >
                    
                        {rotateSlidesData}
                    </OwlCarousel>
                </section>
            </>
        );
    }
}

rotateSlides.propTypes = {
    data: PropTypes.array
};

rotateSlides.defaultProps = {
    data: [
        {
            bgImgClass: "",
            toptitle: "台灣保護服務工作的最高榮譽",
        },
        {
            bgImgClass: "",
            toptitle: "紫絲帶講壇10分鐘精采演說",
        },
        {
            bgImgClass: "",
            toptitle: "防暴大使獎得主 獨得獎金五萬元",
        }
    ]
};

export default rotateSlides;
