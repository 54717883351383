import React, { Component } from 'react';
import Icofont from "react-icofont";
import PropTypes from "prop-types";

export class FormDownload extends Component {
    render() {
        //Start FormDownload Loop
        const servicedata = this.props.formDownloadData.map((formDownload, index ) =>(
            <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                <div className="single-formDownload">
                    <Icofont icon={formDownload.icon} />
                    <h3>{formDownload.title}</h3>
                    <p>{formDownload.content}</p>
                    <p><a href={formDownload.url}>點我下載</a></p>
                    <div className="bg-number">{formDownload.bgnumber}</div>
                </div>
            </div>
        ));
        //End FormDownload Loop

        return (
            <>
                <section id="formDownload" className="formDownload-area">
                    <div className="container">
                        <div className="section-title">
                            <h2>{this.props.sectionTitle} <b>{this.props.sectionTitleSpan}</b></h2>
                            <p>{this.props.sectionContent}</p>
                        </div>
                        <div className="row">{servicedata}</div>
                    </div>
                </section>
            </>
        );
    }
}

FormDownload.propsTypes = {
    sectionTitle: PropTypes.string,
    sectionTitleSpan: PropTypes.string,
    sectionContent: PropTypes.string,
    formDownloadData: PropTypes.array
}

FormDownload.defaultProps = {
    sectionTitle: "",
    sectionTitleSpan: "下載專區",
    sectionContent: "",

    formDownloadData: [
        {
            icon: "icofont-crown",
            title: "徵選辦法",
            content: "衛生福利部第九屆紫絲帶獎徵選辦法",
            url: "/assets/2023/docs/第九屆徵選辦法.odt",
            bgnumber: "1"
        },
        {
            icon: "icofont-tick-boxed",
            title: "同意書",
            content: "衛生福利部第九屆紫絲帶獎活動同意書",
            url: "/assets/2023/docs/第九屆同意書.odt",
            bgnumber: "2"
        },
        {
            icon: "icofont-pencil-alt-1",
            title: "報名表",
            content: "衛生福利部第九屆紫絲帶獎推薦表",
            url: "/assets/2023/docs/第九屆報名表.odt",
            bgnumber: "3"
        }
    ]
}

export default FormDownload

