import React, {useState} from "react";
import "react-modal-video/css/modal-video.min.css";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";

const BannerLogin = () => {
    const [account, setAccount] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();
    const handleForm = (e) => {
        e.preventDefault();
        console.log("account", account);
        console.log("password", password);
        const credential = [
            {account: "gino@cubizsoft.com", pwd:"12345"},
            {account: "ycjimmy@gmail.com", pwd:"12345"}
        ]
        let forward = false;
        credential.map(x=>{
            if(account === x.account && password === x.pwd){
                console.log("success");
                forward = true;
                return;
                // ("/uat");
            }
        });
        if(forward) history.push('/uat');
        // let that = this;
        // axios
        //     .post("https://formcarry.com/s/qJalZsYuqY-", this.state, {
        //         headers: { Accept: "application/json" }
        //     })
        //     .then(function (response) {
        //         document.getElementById("loginForm").reset();
        //         that.setState({
        //             successMsg: "Login success"
        //         });
        //         document.getElementById("loginForm").reset();
        //     })
        //     .catch(function (error) { });

    };

    return (
        <>
            <div id="home" className="main-banner item-bg-two">
            </div>
            <div className="d-table ptb-100">
                <div className="d-table-cell text-left">
                    <div className="container">
                        <div>
                            <ValidationForm
                                id="loginForm"
                                onSubmit={handleForm}
                            >
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <TextInput
                                            name="account"
                                            id="account"
                                            type="email"
                                            required
                                            successMessage=""
                                            errorMessage="請輸入帳號名稱"
                                            className="form-control"
                                            placeholder="Account Name"
                                            autoComplete="off"
                                            onChange={(e)=>setAccount(e.target.value)}
                                        />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="form-group">
                                        <TextInput
                                            name="password"
                                            id="password"
                                            type="password"
                                            required
                                            successMessage=""
                                            errorMessage="請輸入密碼"
                                            className="form-control"
                                            placeholder="Password"
                                            autoComplete="off"
                                            onChange={(e)=>setPassword(e.target.value)}
                                        />
                                        <div className="help-block with-errors"></div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <button type="submit" className="btn btn-primary">Login</button>
                                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                                    <div className="clearfix"></div>
                                </div>
                            </ValidationForm>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BannerLogin;
