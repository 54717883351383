import React, { Component } from 'react';

//Components List
import NavBar from "../components/NavBar";
import NewsDetail from "../components/NewsDetail";
import ReachUs from "../components/ReachUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";

class News extends Component {
    render() {
        return(
            <>
                <NewsDetail />
            </>
        );
    }
}
export default News;


